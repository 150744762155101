<template>
  <b-row class="animated fadeIn">
    <b-col v-if="policy_loaded">


        <div class="card mb-3">
          <div class="card-header">
            <b>{{ stageText() }}</b> {{model.policy_number}}
            <span class="float-right">
              <span><b>Last Modified:</b> {{ extras.created_at }}</span>
              <span class="pl-4"><b>Version: </b>{{ extras.version }}</span>
            </span>
          </div>
        </div>

        <div id="error" class="card border-danger" v-show="error">
          <div class="card-header bg-danger border-danger">

              <span class="text-white font-weight-bolder">
              <i class="fa fa-exclamation-triangle"></i>
               &nbsp;
              {{ error_heading }}
              </span>

          </div>
          <div class="card-body border-danger text-dark font-weight-bold">

            {{ error_text }}

          </div>
        </div>


        <div v-show="stage === 'decline'">

          <div id="decline" class="card border-danger">
            <div class="card-header bg-danger border-danger">

                <span class="text-white font-weight-bolder">
                <i class="fa fa-exclamation-triangle"></i>
                 &nbsp;
                {{ error_heading }}

                </span>

            </div>
            <div class="card-body border-danger text-dark font-weight-bold">

              {{ error_text }}

            </div>
          </div>

        </div>

        <div v-show="stage === 'success'">

          <div class="card border-success">
            <div class="card-header bg-success border-success">

                <span class="text-white font-weight-bolder">
                <i class="fa fa-check"></i>
                  &nbsp;
                Policy Updated
                </span>

            </div>
            <div class="card-body border-danger text-dark font-weight-bold">

              {{ success_text }}

            </div>
          </div>

        </div>

        <!-- START MTA Quote -->

        <div v-show="stage === 'quote'">

          <b-form @submit.stop.prevent="submitMTAQuote" novalidate>

          <b-card header="General Details" class="mb-3">

            <b-form-row class="mb-3">

              <b-col cols="4">
                <label class="">Mortgage Provider</label>
                <b-input v-model="model.mortgage.provider" class="mb-2 mr-sm-2 mb-sm-0"></b-input>
              </b-col>

              <b-col cols="4">
                <label class="">Mortgage Roll Number</label>
                <b-input v-model="model.mortgage.roll_number" class="mb-2 mr-sm-2 mb-sm-0"></b-input>
              </b-col>

              <b-col cols="4">
                <label class="">Third Party Reference</label>
                <b-input v-model="model.third_party_reference" class="mb-2 mr-sm-2 mb-sm-0"></b-input>
              </b-col>

            </b-form-row>

            <b-form-row>

              <b-col cols="4">
                <label class="">Start Date</label>
                <b-form-datepicker v-model="model.start_date" class="mb-2 mr-sm-2 mb-sm-0" :readonly="!model.payment.start_date_override" :state="validateState('start_date')" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  locale="en-GB" @input="changeStartDate"></b-form-datepicker>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                <b-row>
                  <b-col cols="4">
                    <b-form-checkbox v-model="model.payment.start_date_override" class="mt-2">Override?</b-form-checkbox>
                  </b-col>
                  <b-col>
                    <b-form-checkbox v-model="model.payment.start_date_use_on_pdf" class="mt-2">Use on PDF?</b-form-checkbox>
                  </b-col>
                </b-row>

              </b-col>

              <b-col cols="4">
                <label class="">End Date</label>
                <b-form-datepicker v-model="model.end_date" class="mb-2 mr-sm-2 mb-sm-0" readonly :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  locale="en-GB"></b-form-datepicker>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
              </b-col>

              <b-col cols="4">
                <label class="">Effective Date</label>
                <b-form-datepicker v-model="model.effective_date" class="mb-2 mr-sm-2 mb-sm-0" :readonly="!model.payment.effective_date_override" :state="validateState('effective_date')" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  locale="en-GB"></b-form-datepicker>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                <b-form-checkbox v-model="model.payment.effective_date_override" class="mt-2">Override?</b-form-checkbox>

              </b-col>

            </b-form-row>

            <b-form-row v-show="!model.payment.annual || model.payment.annual === 'false' || model.payment.annual === false">

              <b-col cols="4" class="offset-4">

                <label class="">Direct Debit Months remaining</label>
                <b-form-select v-model="model.payment.direct_debit_months" :options="options.direct_debit_months"  :state="validateNestedState('payment', 'direct_debit_months')"></b-form-select>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

              </b-col>

            </b-form-row>

          </b-card>


          <div class="card mb-3" v-for="(v, index) in $v.model.applicants.$each.$iter" :v-bind="index" >

            <div class="card-header">

              <p class="float-left pt-1 mb-0" v-if="getIndex(index) === 1">Policy Holder Details</p>

              <p class="float-left pt-1 mb-0" v-show="getIndex(index) === 2">Secondary Applicant</p>

              <b-button variant="outline-danger" class=" btn-sm float-right" v-show="model.applicants.length > 1 && getIndex(index) === 2"
                        @click="removeApplicant">
                <i class="fa fa-times"></i> Remove Applicant
              </b-button>

              <b-button variant="outline-success" class=" btn-sm float-right" v-show="model.applicants.length === 1"
                        @click="addApplicant">
                <i class="fa fa-plus"></i> Add Applicant
              </b-button>
            </div>

            <div class="card-body">

              <b-form-row>

                <b-col cols="12" class="p-0">

                  <b-form-row>

                    <b-col cols="2">
                      <label class="">Title</label>
                      <b-form-select v-model="v.title.$model" :options="options.titles" :state="validateApplicantState(index, 'title')">
                      <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                      </b-form-select>
                    </b-col>

                    <b-col cols="3">
                      <label class="">Forenames</label>
                      <b-input v-model="v.forename.$model" class="mb-2 mr-sm-2 mb-sm-0" :state="validateApplicantState(index, 'forename')"></b-input>
                      <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                    </b-col>

                    <b-col cols="4">
                      <label class="">Surname</label>
                      <b-input v-model="v.surname.$model" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateApplicantState(index, 'surname')"></b-input>
                      <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                    </b-col>

                    <b-col cols="3">
                      <label class="">Date of Birth</label>
                      <b-input v-model="v.date_of_birth.$model"  class="mb-2 mr-sm-2 mb-sm-0" type="tel" v-mask="'##/##/####'" :state="validateApplicantState(index, 'date_of_birth')"></b-input>
                      <!--<b-form-datepicker v-model="v.date_of_birth.$model" class="mb-2 mr-sm-2 mb-sm-0" :state="validateApplicantState(index, 'date_of_birth')" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  locale="en-GB"></b-form-datepicker>-->
                      <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                    </b-col>

                  </b-form-row>

                  <b-form-row class="mt-2" v-show="getIndex(index) === 1">

                    <b-col cols="6">
                      <label class="">Email</label>
                      <b-input v-model="v.email.$model" class="mb-2 mr-sm-2 mb-sm-0"></b-input>
                    </b-col>

                    <b-col cols="6">
                      <label class="">Telephone</label>
                      <b-input v-model="v.telephone.$model" class="mb-2 mr-sm-2 mb-sm-0"></b-input>
                    </b-col>

                  </b-form-row>

                </b-col>

              </b-form-row>

            </div>
          </div>

          <b-card header="Property Details" class="mb-3">

            <b-form-row>

              <b-col cols="4" class="pr-3">

                <h6 class="mb-3">Property Specific</h6>

                <label class="">Property Type</label>
                <b-form-select v-model="model.property.property_type" :options="options.property_types" required
                               class="mb-3"></b-form-select>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                <label class="">Bedrooms</label>
                <b-form-select v-model="model.property.bedrooms" :options="options.bedrooms" required class="mb-3"></b-form-select>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                <label class="">Year Built</label>
                <b-form-select v-model="model.property.year_built" :options="options.year_built" required class="mb-3"></b-form-select>
                <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

              </b-col>

              <b-col>

                <b-row>

                  <b-col cols="12">

                    <label class="">Is risk address correspondence address?</label>
                    <b-form-select v-model="extras.address_match" :options="options.yes_no" @change="addressMatch"
                                   class="mb-3"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                  </b-col>

                  <b-col cols="6">
                    <h6 class="mb-3">Correspondence Address</h6>

                    <label class="">Address 1</label>
                    <b-input v-model="$v.model.correspondence_address.address1.$model" class="mb-3" @input="addressUpdate" :state="validateNestedState('correspondence_address', 'address1')"></b-input>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                    <label class="">Address 2</label>
                    <b-input v-model="model.correspondence_address.address2" class="mb-3" @input="addressUpdate"></b-input>

                    <label class="">Address 3</label>
                    <b-input v-model="model.correspondence_address.address3" class="mb-3" @input="addressUpdate"></b-input>

                    <label class="">Address 4</label>
                    <b-input v-model="model.correspondence_address.address4" class="mb-3" @input="addressUpdate"></b-input>

                    <label class="">Postcode</label>
                    <b-input v-model="$v.model.correspondence_address.postcode.$model" class="mb-3" @input="addressUpdate" :state="validateNestedState('correspondence_address', 'postcode')"></b-input>
                    <b-form-invalid-feedback >This is a required field.</b-form-invalid-feedback>

                  </b-col>

                  <b-col cols="6" class="pr-3">
                    <h6 class="mb-3">Property Address</h6>

                    <label class="">Address 1</label>
                    <b-input v-model="model.property.address.address1" class="mb-3" @input="corresponenceAddressUpdate" :state="validateNestedNestedState('property', 'address', 'address1')"></b-input>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                    <label class="">Address 2</label>
                    <b-input v-model="model.property.address.address2" class="mb-3" @input="corresponenceAddressUpdate"></b-input>

                    <label class="">Address 3</label>
                    <b-input v-model="model.property.address.address3" class="mb-3" @input="corresponenceAddressUpdate"></b-input>

                    <label class="">Address 4</label>
                    <b-input v-model="model.property.address.address4" class="mb-3" @input="corresponenceAddressUpdate"></b-input>

                    <label class="">Postcode</label>
                    <b-input v-model="model.property.address.postcode" class="mb-3" @input="corresponenceAddressUpdate" :state="validateNestedNestedState('property', 'address', 'postcode')"></b-input>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                  </b-col>

                </b-row>

              </b-col>

            </b-form-row>

          </b-card>


          <b-card header="Agency Details" class="mb-3">

            <b-form-row>

              <b-col cols="4">

                <label class="">Broker Name</label>
                <b-input v-model="model.agency.broker_name"></b-input>

              </b-col>

              <b-col cols="4">

                <label class="">Contact Name</label>
                <b-input v-model="model.agency.contact_name"></b-input>
              </b-col>

              <b-col cols="4">

                <label class="">Firm FSA Reference</label>
                <b-input v-model="model.agency.firm_fsa_reference"></b-input>

              </b-col>

            </b-form-row>

          </b-card>


            <b-alert variant="danger" :show="!covers.buildings_cover & !covers.contents_cover" >
              <i class="fa fa-exclamation-circle"></i>&nbsp;Buildings Cover or Contents Cover is required.
            </b-alert>


          <div class="card mb-3">

            <div class="card-header">
              <p class="float-left pt-1 mb-0">Buildings Cover</p>
              <b-button variant="outline-danger" class=" btn-sm float-right" v-show="covers.buildings_cover"
                        @click="removeCover('buildings_cover')">
                <i class="fa fa-times"></i> Remove Cover
              </b-button>
              <b-button variant="outline-success" class=" btn-sm float-right" v-show="!covers.buildings_cover"
                        @click="addCover('buildings_cover')">
                <i class="fa fa-plus"></i> Add Cover
              </b-button>
            </div>

            <div class="card-body" v-show="covers.buildings_cover">

              <b-form-row>

                <b-col>

                  <label class="">Cover Amount</label>
                  <b-form-select v-model="model.buildings_cover.cover_amount" :options="options.buildings_cover_amounts"
                                 class="mb-3" :state="validateNestedState('buildings_cover', 'cover_amount')"></b-form-select>
                  <b-form-invalid-feedback >This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col>

                  <label class="">Excess</label>
                  <b-form-select v-model="model.buildings_cover.excess" :options="options.cover_excess"
                                 class="mb-3" :state="validateNestedState('buildings_cover', 'excess')"></b-form-select>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col>

                  <label class="">Claim Free Years</label>
                  <b-form-select v-model="model.buildings_cover.claim_free_years"
                                 :options="options.cover_claim_free_years" class="mb-3" :state="validateNestedState('buildings_cover', 'claim_free_years')"></b-form-select>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col>

                  <label class="">Accidental Damage</label>
                  <b-form-select v-model="model.buildings_cover.accidental_damage" :options="options.yes_no"
                                 class="mb-3" :state="validateNestedState('buildings_cover', 'accidental_damage')"></b-form-select>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

              </b-form-row>

            </div>
          </div>


          <div class="card mb-3">

            <div class="card-header">
              <p class="float-left pt-1 mb-0">Contents Cover</p>
              <b-button variant="outline-danger" class=" btn-sm float-right" v-show="covers.contents_cover"
                        @click="removeCover('contents_cover')">
                <i class="fa fa-times"></i> Remove Cover
              </b-button>
              <b-button variant="outline-success" class=" btn-sm float-right" v-show="!covers.contents_cover"
                        @click="addCover('contents_cover')">
                <i class="fa fa-plus"></i> Add Cover
              </b-button>
            </div>

            <div class="card-body" v-show="covers.contents_cover">

              <b-form-row>

                <b-col>

                  <label class="">Cover Amount</label>
                  <b-form-select v-model="model.contents_cover.cover_amount" :options="options.contents_cover_amounts" class="mb-3" :state="validateNestedState('contents_cover', 'cover_amount')"></b-form-select>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col>

                  <label class="">Excess</label>
                  <b-form-select v-model="model.contents_cover.excess" :options="options.cover_excess" class="mb-3" :state="validateNestedState('contents_cover', 'excess')"></b-form-select>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col>

                  <label class="">Claim Free Years</label>
                  <b-form-select v-model="model.contents_cover.claim_free_years" :options="options.cover_claim_free_years" class="mb-3" :state="validateNestedState('contents_cover', 'claim_free_years')"></b-form-select>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col>

                  <label class="">Accidental Damage</label>
                  <b-form-select v-model="model.contents_cover.accidental_damage" :options="options.yes_no" class="mb-3" :state="validateNestedState('contents_cover', 'accidental_damage')"></b-form-select>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col>

                  <label class="">Unspecified Cover Amount</label>
                  <b-form-select v-model="model.contents_cover.unspecified_cover_amount" :options="options.unspecified_cover_amounts" class="mb-3"></b-form-select>

                </b-col>

              </b-form-row>

            </div>
          </div>


          <div class="card mb-3" v-show="covers.contents_cover">

            <div class="card-header">
              <p class="float-left pt-1 mb-0">High risk items away from the home</p>
              <b-button variant="outline-danger" class=" btn-sm float-right"
                        v-show="model.contents_cover.specified_items.length > 0 && model.contents_cover"
                        @click="removeCover('specified_items')">
                <i class="fa fa-times"></i> Remove Cover
              </b-button>
              <b-button variant="outline-success" class=" btn-sm float-right"
                        v-show="model.contents_cover.specified_items.length <= 0 && model.contents_cover"
                        @click="addSpecifiedItem">
                <i class="fa fa-plus"></i> Add Cover
              </b-button>
            </div>

            <div class="card-body" v-show="notEmptyObject(model.contents_cover.specified_items) && model.contents_cover">

              <b-form-row v-if="model.contents_cover.specified_items.length > 0" v-for="(item, index) in model.contents_cover.specified_items" :key="index">

                <b-col cols="3">

                  <label class="">Type</label>
                  <b-form-select v-model="item.type" :options="options.specified_item_types" class="mb-3" :state="validateItemState('specified_items', index, 'type')"></b-form-select>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col>

                  <label class="">Description</label>
                  <b-input v-model="item.description" :state="validateItemState('specified_items', index, 'description')"></b-input>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col cols="2">

                  <label class="">Value (&pound;)</label>
                  <b-input v-model="item.value" type="number" :state="validateItemState('specified_items', index, 'value')"></b-input>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col cols="1" class="text-center">

                  <label class="">&nbsp;</label> <br>

                  <b-button variant="outline-danger" class="" @click="deleteSpecifiedItem(index)">
                    <i class="fa fa-times"></i>
                  </b-button>

                </b-col>

              </b-form-row>


            </div>

            <div class="card-footer bg-white"
                 v-show="notEmptyObject(model.contents_cover.specified_items) && model.contents_cover">

              <b-button variant="outline-success" class="" @click="addSpecifiedItem">
                <i class="fa fa-plus"></i> Add Item
              </b-button>

            </div>

          </div>


          <div class="card mb-3" v-show="covers.contents_cover">

            <div class="card-header">
              <p class="float-left pt-1 mb-0">High risk items within the home</p>
              <b-button variant="outline-danger" class=" btn-sm float-right"
                        v-show="model.contents_cover.high_risk_items.length > 0 && model.contents_cover"
                        @click="removeCover('high_risk_items')">
                <i class="fa fa-times"></i> Remove Cover
              </b-button>
              <b-button variant="outline-success" class=" btn-sm float-right"
                        v-show="model.contents_cover.high_risk_items.length <= 0 && model.contents_cover"
                        @click="addHighRiskItem">
                <i class="fa fa-plus"></i> Add Cover
              </b-button>
            </div>

            <div class="card-body" v-show="covers.contents_cover && notEmptyObject(model.contents_cover.high_risk_items) && model.contents_cover">

              <b-form-row v-if="model.contents_cover.high_risk_items.length > 0" v-for="(item, index) in model.contents_cover.high_risk_items" :key="index">

                <b-col cols="3">

                  <label class="">Type</label>
                  <b-form-select v-model="item.type" :options="options.high_risk_item_types" class="mb-3" :state="validateItemState('high_risk_items', index, 'type')"></b-form-select>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col>

                  <label class="">Description</label>
                  <b-input v-model="item.description" :state="validateItemState('high_risk_items', index, 'description')"></b-input>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col cols="2">

                  <label class="">Value (&pound;)</label>
                  <b-input v-model="item.value" type="number" :state="validateItemState('high_risk_items', index, 'value')"></b-input>
                  <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                </b-col>

                <b-col cols="1" class="text-center">

                  <label class="">&nbsp;</label> <br>

                  <b-button variant="outline-danger" class="" @click="deleteHighRiskItem(index)">
                    <i class="fa fa-times"></i>
                  </b-button>

                </b-col>

              </b-form-row>

            </div>

            <div class="card-footer bg-white" v-show="covers.contents_cover && notEmptyObject(model.contents_cover.high_risk_items) ">

              <b-button variant="outline-success" class="" @click="addHighRiskItem">
                <i class="fa fa-plus"></i> Add Item
              </b-button>

            </div>
          </div>


          <b-card header="Bank Details" class="mb-3">

            <b-form-row>

              <b-col cols="6">

                <b-form-row>

                  <b-col cols="12" class="mb-3">

                    <label class="">Account Name</label>
                    <b-input v-model="$v.model.payment.account_name.$model" :state="validateNestedState('payment', 'account_name')"></b-input>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                  </b-col>

                  <b-col cols="6">

                    <label class="">Account Number</label>
                    <b-input v-model="$v.model.payment.account_number.$model" :state="validateNestedState('payment', 'account_number')"></b-input>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </b-col>

                  <b-col cols="6">

                    <label class="">Account Sort Code</label>
                    <b-input v-model="$v.model.payment.sort_code.$model" :state="validateNestedState('payment', 'sort_code')" ></b-input>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                  </b-col>

                </b-form-row>
              </b-col>

              <b-col cols="6">

                <b-form-row>

                  <b-col cols="12" class="mb-3">

                    <label class="">Payment Type</label>
                    <b-form-select v-model="model.payment.annual" :options="options.payment_types" :state="validateNestedState('payment', 'annual')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                  </b-col>

                </b-form-row>

                <b-form-row>

                  <b-col cols="12">

                    <label class="">Direct Debit</label>
                    <b-form-select v-model="model.payment.direct_debit" :options="options.yes_no" :state="validateNestedState('payment', 'direct_debit')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                  </b-col>

                </b-form-row>

              </b-col>

            </b-form-row>

          </b-card>

          <b-row>

            <b-col cols="6" class="pr-1">

              <b-card header="Addons" class="mb-3">

                <b-form-row>

                  <b-col cols="6">

                    <label class="">Home Emergency Cover</label>
                    <b-form-select v-model="model.optional_extras.hec" :options="options.yes_no" required></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                  </b-col>

                  <b-col cols="6">

                    <label class="">Family Legal Cover</label>
                    <b-form-select v-model="model.optional_extras.lah" :options="options.yes_no" required></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                  </b-col>

                </b-form-row>

              </b-card>

            </b-col>

            <b-col cols="6" class="pl-1">

              <b-card header="Insurer" class="mb-3" cols="6">

                <b-form-row>

                  <b-col cols="6">

                    <label class="">Insurer</label>
                    <b-form-select v-model="model.insurer_code" :options="options.insurers" required :state="validateState('insurer_code')"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>

                  </b-col>

                </b-form-row>

              </b-card>

            </b-col>

          </b-row>

          <div class="card">
            <div class="card-footer">
              <b-button type="submit" variant="success" class="float-right" @click="submitMTAQuote">Amend Policy</b-button>
            </div>
          </div>

          </b-form>

        </div>


        <!-- START MTA Premium -->

        <div v-show="stage === 'premium'">

          <div class="card mb-3">

            <div class="card-header">
              <p class="float-left pt-1 mb-0">Premium Differences</p>
              <b-button variant="outline-secondary" class=" btn-sm float-right" @click="switchMTAStage('quote')">
                <i class="fa fa-arrow-left"></i> &nbsp;Back to Quote
              </b-button>
            </div>

            <div class="card-body">

              <table class="table">

                <tr>
                  <th>
                    <b-button variant="outline-info" class=" btn-sm" @click="toggleShowBreakdown"><span v-if="!show_breakdown">Show</span> <span v-else>Hide</span> Breakdown</b-button>
                  </th>
                  <th v-show="overidden_premium">
                    Overidden
                  </th>
                  <th>
                    Adjusted
                  </th>
                  <th>
                    Original
                  </th>
                </tr>
                <tr>
                  <th colspan="4">
                    Monthly
                  </th>
                </tr>
                <tr>
                  <td>
                    Total Premium
                  </td>
                  <td v-show="overidden_premium">
                    &pound;{{ overidden_premium_response.premium.monthly.total_premium.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].monthly.total_premium.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.monthly.total_premium.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Discount
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.monthly.discount.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].monthly.discount.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.monthly.discount.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Credit Charge (%)
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    {{ overidden_premium_response.premium.monthly.credit_charge_percent.toFixed(2) * 100 }}%
                  </td>
                  <td>
                    {{ quote_response.insurers[0].premiums[0].monthly.credit_charge_percent.toFixed(2) * 100 }}%
                  </td>
                  <td>
                    {{ previous_premium_response.monthly.credit_charge_percent.toFixed(2) * 100 }}%
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Credit Charge
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.monthly.credit_charge.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].monthly.credit_charge.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.monthly.credit_charge.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Family Legal Expenses
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.monthly.family_legal_protection.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].monthly.family_legal_protection.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.monthly.family_legal_protection.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Home Emergency Cover
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.monthly.home_emergency_cover.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].monthly.home_emergency_cover.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.monthly.home_emergency_cover.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Policy Fee
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.monthly.policy_fee.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].monthly.policy_fee.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.monthly.policy_fee.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    IPT (%)
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    {{ overidden_premium_response.premium.monthly.ipt_percent.toFixed(2) * 100 }}%
                  </td>
                  <td>
                    {{ quote_response.insurers[0].premiums[0].monthly.ipt_percent.toFixed(2) * 100 }}%
                  </td>
                  <td>
                    {{ previous_premium_response.monthly.ipt_percent.toFixed(2) * 100 }}%
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    IPT (£)
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.monthly.ipt.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].monthly.ipt.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.monthly.ipt.toFixed(2) }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Premium
                  </td>
                  <td v-if="overidden_premium">
                    &pound;{{ overidden_premium_response.premium.monthly.premium.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].monthly.premium.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.monthly.premium.toFixed(2) }}
                  </td>
                </tr>

                <tr>
                  <th colspan="4">
                    Yearly
                  </th>
                </tr>
                <tr>
                  <td>
                    Total Premium
                  </td>
                  <td v-if="overidden_premium">
                    &pound;{{ overidden_premium_response.premium.yearly.total_premium.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].yearly.total_premium.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.yearly.total_premium.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Discount
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.yearly.discount.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].yearly.discount.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.yearly.discount.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Credit Charge (%)
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    {{ overidden_premium_response.premium.yearly.credit_charge_percent.toFixed(2) * 100 }}%
                  </td>
                  <td>
                    {{ quote_response.insurers[0].premiums[0].yearly.credit_charge_percent.toFixed(2) * 100 }}%
                  </td>
                  <td>
                    {{ previous_premium_response.yearly.credit_charge_percent.toFixed(2) * 100 }}%
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Credit Charge
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.yearly.credit_charge.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].yearly.credit_charge.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.yearly.credit_charge.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Family Legal Expenses
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.yearly.family_legal_protection.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].yearly.family_legal_protection.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.yearly.family_legal_protection.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Home Emergency Cover
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.yearly.home_emergency_cover.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].yearly.home_emergency_cover.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.yearly.home_emergency_cover.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    Policy Fee
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.yearly.policy_fee.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].yearly.policy_fee.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.yearly.policy_fee.toFixed(2) }}
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    IPT (%)
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    {{ overidden_premium_response.premium.yearly.ipt_percent.toFixed(2) * 100 }}%
                  </td>
                  <td>
                    {{ quote_response.insurers[0].premiums[0].yearly.ipt_percent.toFixed(2) * 100 }}%
                  </td>
                  <td>
                    {{ previous_premium_response.yearly.ipt_percent.toFixed(2) * 100 }}%
                  </td>
                </tr>
                <tr v-show="show_breakdown">
                  <td>
                    IPT (£)
                  </td>
                  <td v-if="(overidden_premium && show_breakdown)">
                    &pound;{{ overidden_premium_response.premium.yearly.ipt.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].yearly.ipt.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.yearly.ipt.toFixed(2) }}
                  </td>
                </tr>
                <tr>
                  <td>
                    Premium
                  </td>
                  <td v-if="overidden_premium">
                    &pound;{{ overidden_premium_response.premium.yearly.premium.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].premiums[0].yearly.premium.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ previous_premium_response.yearly.premium.toFixed(2) }}
                  </td>
                </tr>

                <tr>
                  <th>
                    Underwriter Premium
                  </th>
                  <td v-show="overidden_premium">
                    &pound;{{ overidden_premium_response.underwriter_premium.toFixed(2) }}
                  </td>
                  <td>
                    &pound;{{ quote_response.insurers[0].underwriter_premium.toFixed(2) }}
                  </td>
                  <td>
                    <span v-if="overidden_premium_response.underwriter_premium">&pound;{{ this.previous_underwriter_premium.toFixed(2) }}</span>
                  </td>
                </tr>

              </table>

            </div>

          </div>

          <div class="card">
            <div class="card-header">
              Endorsements
            </div>
            <div class="card-body">
              <table class="table">
                <thead>
                <tr>
                  <th>Code</th>
                  <th>Title</th>
                </tr>
                </thead>
                <tbody>
                  <tr  v-for="(endorsement, index) in quote_response.insurers[0].full_endorsements" :key="index">
                    <td>EN{{ endorsement.code }}</td>
                    <td>{{ endorsement.title }}</td>
                  </tr>

                  <tr v-if="manual_endorsements.length > 0" v-for="(endorsement, endindex) in manual_endorsements" cols="12" class="my-2 mb-3" :key="endindex">

                    <td colspan="2">
                    <label class="w-100 clearfix">Please select a endorsement <b-button variant="btn-link" class="text-danger text-small btn-sm float-right" @click="removeEndorsement(endindex)">Remove</b-button></label>
                    <b-form-select v-model="endorsement.code" :options="all_endorsements" :selected="endorsement" :state="validateManualEndorsementState(endindex)"></b-form-select>
                    <b-form-invalid-feedback>This is a required field.</b-form-invalid-feedback>
                    </td>

                  </tr>

                </tbody>
              </table>
            </div>
            <div class="card-footer">
              <b-button variant="outline-success" class="btn-sm pull-right" @click="addEndorsement">
                  <i class="fa fa-plus"></i> Add Endorsement
              </b-button>
            </div>
          </div>


          <b-col cols="12" class="mt-3">
            <h5>
              Manual Endorsements

            </h5>
          </b-col>



          <div class="card">
            <div class="card-footer">

              <b-form-row>
                <b-col class="col" v-show="override_permission">
                  <b-form-row>
                    <b-col cols="4">
                      <b-input-group size="md" prepend="£">
                        <b-form-input type="number" v-model="override_premium" placeholder="100.00"></b-form-input>
                      </b-input-group>
                    </b-col>
                    <b-col cols="6">
                      <b-button type="submit" variant="outline-success"  :disabled="override_premium <= 0"  @click="submitPremiumOverride" >Override Premium</b-button>
                      <b-button type="submit" variant="outline-danger" v-show="remove_premium_override" class="ml-2"  @click="removeOverridePremium" >Remove</b-button>
                    </b-col>
                  </b-form-row>
                </b-col>

                <b-col>
                  <b-button type="submit" variant="success" class="float-right" @click="submitMTAConfirm">Confirm & Process MTA Adjustment</b-button>
                </b-col>

              </b-form-row>

            </div>
          </div>

        </div>

        <!-- END MTA Premium -->

    </b-col>
  </b-row>
</template>

<script>
  import axios from '../../../shared/axios/auth'
  import MTAData from './mta_data'
  import MTAQuoteResponse from './mta_quote_response'
  import MTAOverridePremium from './mta_override_premium'
  import { validationMixin } from 'vuelidate'
  import { required, email, requiredIf, numeric, decimal } from "vuelidate/lib/validators";
  import { BFormDatepicker } from 'bootstrap-vue'
  import {mask} from 'vue-the-mask'
  import moment from 'moment'

  function leapYear(year) {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
  }

  const isDate = (value) => moment(value, 'DD/MM/YYYY', true).isValid()
  const isNotInFuture = (value) => moment(value, 'DD/MM/YYYY', true).isSameOrBefore(new Date());

  export default {
    components: {
      BFormDatepicker
    },
    directives: {mask},
    mixin: [validationMixin],
    data() {
      return {
        selected: null,
        test: false,
        error: false,
        error_heading: 'There was a error',
        error_text: 'There was a error',
        success_text: 'Policy has been updated.',
        all_endorsements: [],
        success: false,
        mta_id: this.$route.params.id,
        policy_loaded: false,
        model: {},
        quote_response: MTAQuoteResponse,
        previous_premium_response: MTAQuoteResponse,
        previous_underwriter_premium: 0.00,
        overidden_premium_response: MTAOverridePremium,
        show_breakdown: false,
        overidden_premium: false,
        stage: 'quote',
        start_date_override: false,
        start_date_use_on_pdf: false,
        effective_date_override: false,
        effective_date: new Date(),
        override_permission: true,
        override_premium: null,
        remove_premium_override: false,
        extras: null,
        covers: null,
        specified_invividual_limit_min: 2000,
        specified_invividual_limit_max: 7000,
        specified_max_limit: 15000,
        manual_endorsements: [],
        existing_endorsements: []
      }
    },
    validations() {

      var standard_validations = {
        start_date: {
          required
        },
        effective_date: {
          required
        },
        end_date: {
          required
        },
        insurer_code: {
          required
        },
        applicants: {
          $each: {
            title: {
              required
            },
            forename: {
              required
            },
            surname: {
              required
            },
            date_of_birth: {
              required,
              isDate,
              isNotInFuture
            },
            email: {
              email
            },
            telephone: {

            }
          }
        },
        property: {
          property_type: {
            required
          },
          bedrooms: {
            required
          },
          year_built: {
            required
          },
          address: {
            address1: {
              required
            },
            postcode: {
              required
            }
          }
        },
        correspondence_address: {
          address1: {
            required
          },
          postcode: {
            required
          }
        },
        optional_extras: {
          hec: {
            required
          },
          lah: {
            required
          }
        }
      };

      if (this.covers) {

      if (this.covers.buildings_cover) {

        var buildings_validation = {
          buildings_cover: {
            cover_amount: {
              required
            },
            excess: {
              required
            },
            claim_free_years: {
              required
            },
            accidental_damage: {
              required
            }
          }
        }

      }

      if (this.covers.contents_cover) {

        var contents_validation = {
          contents_cover: {
            cover_amount: {
              required
            },
            excess: {
              required
            },
            claim_free_years: {
              required
            },
            accidental_damage: {
              required
            },
            specified_items: {
              $each: {
                type: {
                  required
                },
                description: {
                  required
                },
                value: {
                  decimal,
                  required
                }
              }
            },
            high_risk_items: {
              $each: {
                type: {
                  required
                },
                description: {
                  required
                },
                value: {
                  decimal,
                  required
                }
              }
            }
          }
        }
      }

        if (this.model.payment.annual) {

          var payment_validation = {
            payment: {
              account_name: {
                required
              },
              account_number: {
                numeric,
                required
              },
              sort_code: {
                numeric,
                required
              },
              annual: {
                required
              },
              direct_debit: {
                required
              },
            }
          }

          var standard_inc_payment_validations = Object.assign({}, standard_validations, payment_validation);

          return { model: Object.assign(
            {}, standard_inc_payment_validations, contents_validation, buildings_validation
            ),
          }


        } else {

          var payment_monthly_validation = {
            payment: {
              account_name: {
                required
              },
              account_number: {
                numeric,
                required
              },
              sort_code: {
                numeric,
                required
              },
              annual: {
                required
              },
              direct_debit: {
                required
              },
              direct_debit_months: {
                required
              }
            }
          };

          var standard_inc_payment_validations_2 = Object.assign({}, standard_validations, payment_monthly_validation);

          return { model: Object.assign(
            {}, standard_inc_payment_validations_2, contents_validation, buildings_validation,
            ),
          }

        }

      }
    },
    methods: {
      changeStartDate() {

        var someDate = new Date(this.$v.model.start_date.$model);

        var year = someDate.getFullYear()

            if (leapYear(parseInt(year))) {
                var leap_year = true;
            } else {
                var leap_year = false;
            }

            if (leap_year) {
                var feb_end_day = 29;
            } else {
                var feb_end_day = 28;
            }

            if (someDate <= new Date(year, 2 - 1, feb_end_day)) {
                if (leap_year) {
                    var numberOfDaysToAdd = 365;
                } else {
                    var numberOfDaysToAdd = 364;
                }
            } else {
                if (leap_year) {
                    var numberOfDaysToAdd = 364;
                } else {
                    if (leapYear(parseInt(year) + 1)) {
                        var numberOfDaysToAdd = 365;
                    } else {
                        var numberOfDaysToAdd = 364;
                    }
                }
            }

            someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
            someDate.setHours(0, 0, 0, 0)


            var dd = someDate.getDate();
            var mm = someDate.getMonth() + 1;
            var y = someDate.getFullYear();

          this.$v.model.end_date.$model = String(y) + '-' + String(mm) + '-' + String(dd)

      },
      validateApplicantState(index, name) {
        if (this.$v.model.applicants) {
          if (this.$v.model.applicants.$each[index]) {
            if (this.$v.model.applicants.$each[index][name]) {
                const {$dirty, $error} = this.$v.model.applicants.$each[index][name];
                return $dirty ? !$error : null;
            }
          }
        }
      },
      validateItemState(type, index, name) {
        if (this.$v.model.contents_cover) {
          if (this.$v.model.contents_cover[type]) {
            if (this.$v.model.contents_cover[type].$each[index]) {
              if (this.$v.model.contents_cover[type].$each[index][name]) {
                const {$dirty, $error} = this.$v.model.contents_cover[type].$each[index][name];
                return $dirty ? !$error : null;
              }
            }
          }
        }
      },
      validateManualEndorsementState(index) {
        if (this.manual_endorsements) {
          if (this.manual_endorsements[index]) {
            if (this.manual_endorsements[index].code) {
              return Boolean(this.manual_endorsements[index].code);
            }
          }
        }
      },
      validateState(name) {

        if (this.$v.model[name]) {
          const {$dirty, $error} = this.$v.model[name];
          return $dirty ? !$error : null;
        }
      },
      validateNestedState(key, name) {

        if (this.$v.model[key]) {
          if (this.$v.model[key][name]) {
            const { $dirty, $error } = this.$v.model[key][name];
            return $dirty ? !$error : null;
          }
        }
      },
      validateNestedNestedState(parent, key, name) {
        if (this.$v.model[parent]) {
          if (this.$v.model[parent][key]) {
            if (this.$v.model[parent][key][name]) {
              const {$dirty, $error} = this.$v.model[parent][key][name];
              return $dirty ? !$error : null;
            }
          }
        }
      },
      getIndex: function(index) {
        return parseInt(index) + 1
      },
      addEndorsement() {
        const end = {
          code: ""
        };
        this.model.manual_endorsements = true
        this.manual_endorsements.push(end);
        //this.$options.validations()
      },
      removeEndorsement(id) {
        this.$delete(this.manual_endorsements, id)
      },
      addApplicant() {
        const applicant = {
          primary: false,
          title: null,
          forename: "",
          surname: "",
          date_of_birth: ""
        };
        this.model.applicants.push(applicant);
        this.$options.validations()
      },
      removeApplicant() {
        this.$delete(this.model.applicants, 1);
        this.$options.validations()
      },
      addSpecifiedItem() {

        const item = {
          type: '',
          description: '',
          value: '',
        };
        this.model.contents_cover.specified_items.push(item)
        this.$options.validations()
      },
      deleteSpecifiedItem(id) {
        this.$delete(this.model.contents_cover.specified_items, id)
        this.$options.validations()
      },
      addHighRiskItem() {

        const item = {
          type: '',
          description: '',
          value: '',
        };
        this.model.contents_cover.high_risk_items.push(item)
        this.$options.validations()
      },
      deleteHighRiskItem(id) {
        this.$delete(this.model.contents_cover.high_risk_items, id)
        this.$options.validations()
      },
      addressMatch() {
        if (this.extras.address_match) {
          this.model.property.address.address1 = this.model.correspondence_address.address1;
          this.model.property.address.address2 = this.model.correspondence_address.address2;
          this.model.property.address.address3 = this.model.correspondence_address.address3;
          this.model.property.address.address4 = this.model.correspondence_address.address4;
          this.model.property.address.postcode = this.model.correspondence_address.postcode;
        }
        if (this.extras.address_match === false || this.extras.address_match === 'false') {
          this.model.property.address.address1 = null;
          this.model.property.address.address2 = null;
          this.model.property.address.address3 = null;
          this.model.property.address.address4 = null;
          this.model.property.address.postcode = null;
        }
      },
      addressUpdate() {
        if (this.extras.address_match === true || this.extras.address_match === 'true') {
          this.model.property.address.address1 = this.model.correspondence_address.address1;
          this.model.property.address.address2 = this.model.correspondence_address.address2;
          this.model.property.address.address3 = this.model.correspondence_address.address3;
          this.model.property.address.address4 = this.model.correspondence_address.address4;
          this.model.property.address.postcode = this.model.correspondence_address.postcode;
        }
      },
      corresponenceAddressUpdate() {
        if (this.extras.address_match === true || this.extras.address_match === 'true') {
          this.model.correspondence_address.address1 = this.model.property.address.address1;
          this.model.correspondence_address.address2 = this.model.property.address.address2;
          this.model.correspondence_address.address3 = this.model.property.address.address3;
          this.model.correspondence_address.address4 = this.model.property.address.address4;
          this.model.correspondence_address.postcode = this.model.property.address.postcode;
        }
      },
      addCover(name) {

        const buildings_cover = {
          "excess": '',
          "cover_amount": '',
          "claim_free_years": '',
          "accidental_damage": false
        };

        const contents_cover = {
          "excess": '',
          "cover_amount": '',
          "claim_free_years": '',
          "accidental_damage": false,
          "unspecified_cover_amount": 0,
          "specified_items": [],
          "high_risk_items": []

        };

        if (name === 'contents_cover') {
          this.model.contents_cover = contents_cover;
          this.covers.contents_cover = true;
        } else if (name === 'buildings_cover') {
          this.model.buildings_cover = buildings_cover;
          this.covers.buildings_cover = true;
        }
        this.$options.validations()
      },
      removeCover(name) {

        const buildings_cover = {
          "excess": {},
          "cover_amount": {},
          "claim_free_years": {},
          "accidental_damage": false
        };

        const contents_cover = {
          "excess": {},
          "cover_amount": {},
          "claim_free_years": {},
          "accidental_damage": false,
          "unspecified_cover_amount": 0,
          "specified_items": [],
          "high_risk_items": []

        };

        if (name === 'high_risk_items') {
          this.model.contents_cover.high_risk_items = []
        } else if (name === 'specified_items') {
          this.model.contents_cover.specified_items = []
        } else if (name === 'contents_cover') {
          this.model.contents_cover = contents_cover;
          this.covers.contents_cover = false;
        } else if (name === 'buildings_cover') {
          this.model.buildings_cover = buildings_cover;
          this.covers.buildings_cover = false;
        }
        this.$options.validations()
      },
      notEmptyObject(someObject) {
        if (someObject === null || someObject === undefined) {
          return false
        } else if (Array.isArray(someObject)) {
          return Object.keys(someObject).length
        } else if (Object.keys(someObject)) {
          return Object.keys(someObject)
        }
      },
      removeOverridePremium() {
        this.overidden_premium = false
        this.override_premium = null
        this.remove_premium_override = false
      },
      toggleShowBreakdown() {
        this.show_breakdown = !this.show_breakdown
      },
      switchMTAStage(stage) {
        this.stage = stage;
      },
      percentCalc(previous, current) {
        const percent = (((parseFloat(previous) - parseFloat(current)) / parseFloat(previous)) * 100).toFixed(2);
        if (percent < 0) {
          return percent * 100
        } else {
          return percent
        }
      },
      stageText() {
        if (this.stage === "success") {
          return 'Adjusted Policy:'
        } else {
          return 'Adjusting Policy:'
        }

      },
      submitMTAQuote() {

        this.error = false;

        this.$v.$touch();

        if(this.$v.$anyError) {

          if (!this.model.contents_cover && !this.model.buildings_cover) {
            return
          }

          window.scrollTo(document.querySelector('#error').offsetTop, 100);
          this.error = true;
          this.error_heading = 'There are errors on the form';
          this.error_text = 'Please fix the errors and re-submit the form';
          return

        } else {

          const post_data = JSON.parse(JSON.stringify(this.model));

          for (let applicant in post_data.applicants) {

            let dob_string = post_data.applicants[applicant].date_of_birth;
            let split_dob = dob_string.split("/")

            let day = split_dob[0]
            let month = split_dob[1]
            let year = split_dob[2]

            post_data.applicants[applicant].date_of_birth = year + '-' + month + '-' + day

          }

          // Set Quote Type

          if (!this.covers.buildings_cover && this.covers.contents_cover) {

            // Contents
            delete post_data['buildings_cover'];
            post_data['quote_type'] = 3

          } else if (!this.covers.contents_cover && this.covers.buildings_cover) {

            // Buildings
            delete post_data['contents_cover'];
            post_data['quote_type'] = 2

          } else {

            // Buildings & Contents
            post_data['quote_type'] = 1

          }

          let url = '/mta/?product=' + localStorage.getItem('selected_product');

          axios.post(url, post_data).then(
            response => {
              console.log(response.data)
              this.quote_response = response.data;
              this.error = false;
              this.stage = "premium";

            }
          ).catch(error => {
              console.log('post error');
              console.log(error.response);

              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }

              // Decline logic

              if (error.response.status === 400) {

                if (error.response.data) {

                  if (error.response.data.insurer) {

                    // Decline

                    if (error.response.data.insurer.decline) {
                      this.error_heading = 'These changes have resulted in a Decline';
                      this.error_text = error.response.data.insurer.reason;
                      this.stage = 'decline';
                      window.scrollTo(document.querySelector('#decline').offsetTop, 100);
                    }

                  }
                } else {

                  this.error = true;
                  this.error_heading = 'There was a problem';
                  this.error_text = 'Please try and re-submit the form';
                  this.stage = 'quote';

                }

              }

          });

        }

      },
      submitPremiumOverride() {

        this.error = false;

        if (this.override_premium > 0) {
          this.error = false;

          const data = {
            "policy_number": this.model.policy_number,
            "premium": this.override_premium

          };
          let url = '/mta/override/?product=' + localStorage.getItem('selected_product');

          axios.post(url, data).then(res => {
            this.overidden_premium_response = res.data;
            this.overidden_premium = true
            this.remove_premium_override = true

          }).catch(error => {
            console.log('premium error');
            console.log(error)
            this.overidden_premium = false
            this.remove_premium_override = true
            this.error = true
            this.error_heading = 'There was a problem'
            this.error_text = 'Please try and re-submit the override premium form'
          })

        } else {
          console.log('error premium 0')
        }

      },
      submitMTAConfirm() {

        let  put_data = {};

        let manual_endorsements = false;

         if (this.manual_endorsements.length > 0) {
            manual_endorsements = true;
         }

        if (this.overidden_premium === 'true' || this.overidden_premium === true) {

            put_data = {
              policy_number: this.model.policy_number,
              premium_override: true,
              premium: this.override_premium,
              manual_endorsements: manual_endorsements
            };

            if (manual_endorsements) {
              let manual_endorsements_list = [];
              this.manual_endorsements.forEach(function (end) {
                manual_endorsements_list.push(end.code)
              });
              put_data['manual_endorsements_list'] = manual_endorsements_list
            }

        } else {

          put_data = {
            policy_number: this.model.policy_number,
            premium_override: false,
            manual_endorsements: manual_endorsements
          };

         if (manual_endorsements) {
            let manual_endorsements_list = [];
            this.manual_endorsements.forEach(function (end) {
              manual_endorsements_list.push(end.code)
            });
            put_data['manual_endorsements_list'] = manual_endorsements_list
          }

        }

        let url = '/mta/?product=' + localStorage.getItem('selected_product');

        axios.put(url, put_data).then(
          response => {
            this.success_text = this.model.policy_number + ' - Policy has been updated.';
            if(!this.extras.draft) {
              this.extras.version += 1;
            }
            this.extras.created_at = 'Now';
            this.stage = 'success';
            this.error = false;
          }
        ).catch(error => {

            // Unauthorised
            if (error.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log('post error');
            console.log(error.response)
            this.error = true
            this.error_heading = 'There was a problem'
            this.error_text = 'Please try and re-submit the form'
        });

      }
    },
    beforeCreate() {
       let url = '/mta/' + this.$route.params.id + '/?product=' + localStorage.getItem('selected_product');

      axios.get(url).then(
          response => {
            this.model = response.data.data;
            this.options = response.data.options;
            this.extras = response.data.extras;
            this.covers = response.data.covers;
            this.previous_premium_response = response.data.current_premium;
            this.policy_loaded = true;
            this.previous_underwriter_premium = response.data.current_underwriter_premium;
            this.all_endorsements = response.data.all_endorsements;
            this.existing_endorsements = response.data.endorsements;
            this.$options.validations()
          }
        ).catch(error => {

            // Unauthorised
            if (error.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log('get error');
            console.log(error)
        });

    },
  }

</script>

<style scoped>

  .btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    margin-left: 5px;
  }

  .btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-right: 6px;
  }

  .hand-cursor {
    cursor: pointer;
  }

</style>
