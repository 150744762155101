

const MTAQuoteResponse = {
  "policy_number": "UNS0073432",
  "valid_until": "2020-07-23",
  "insurers": [
    {
      "insurer_code": 1,
      "insurer_name": "MID",
      "refer": false,
      "refer_reason": [
      ],
      "premiums": [
        {
          "level": 1,
          "commission": 24.0,
          "monthly": {
            "premium": 21.83,
            "ipt": 2.62,
            "ipt_percent": 0.12,
            "policy_fee": 3.0,
            "family_legal_protection": 0.0,
            "home_emergency_cover": 4.0,
            "credit_charge": 3.77,
            "credit_charge_percent": 0.12,
            "discount": -0.0,
            "total_premium": 35.22
          },
          "yearly": {
            "premium": 261.91,
            "ipt": 31.43,
            "ipt_percent": 0.12,
            "policy_fee": 36.0,
            "family_legal_protection": 0.0,
            "home_emergency_cover": 48.0,
            "credit_charge": 0.0,
            "credit_charge_percent": 0.0,
            "discount": -0.0,
            "total_premium": 377.33
          }
        }
      ],
      "endorsements": [
        "EN001"
      ],
      "eligibility": [
        1,
        2,
        3,
        4,
        6,
        7,
        8,
        9,
        11,
        12,
        13,
        14,
        15,
        16,
        17
      ],
      "underwriter_premium": 183.33,
      "full_endorsements": [
        {
          "code": "001",
          "title": "Proof of Value"
        }
      ]
    }
  ],
  "previous_refer": true,
  "previous_refer_data": {
    "manual_endorsements": true,
    "manual_endorsements_list": [
      "001",
      "002",
      "003"
    ]
  }
}


export default MTAQuoteResponse
