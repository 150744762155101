

const MTAOverridePremium = {
            "policy_number": "UINS123433",
            "premium": {
                "monthly": {
                    "premium": 22.0,
                    "ipt": 3.0,
                    "ipt_percent": 0.12,
                    "policy_fee": 3.0,
                    "home_emergency_cover": 0.0,
                    "family_legal_protection": 0.0,
                    "credit_charge": 3.0,
                    "credit_charge_percent": 0.12,
                    "discount": 0,
                    "total_premium": 28.0
                },
                "yearly": {
                    "premium": 228.0,
                    "ipt": 36.0,
                    "ipt_percent": 0.12,
                    "policy_fee": 36.0,
                    "home_emergency_cover": 0.0,
                    "family_legal_protection": 0.0,
                    "credit_charge": 0,
                    "credit_charge_percent": 0,
                    "discount": 0,
                    "total_premium": 300.0
                }
            },
            "underwriter_premium": 0.00,
        };

export default MTAOverridePremium
